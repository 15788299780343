
<template>
    
        <nav class="pull-right" aria-label="Page navigation example">
        <ul class="pagination">
       
        <li v-if="pagination.prevPageUrl" @click.prevent="$emit('prev');" class="page-item" style="background:#00B0E4;">
            <a class="page-link mylink" href="#" style="background:#00B0E4; color:#fff !important;">Previous</a>
        </li>
       

        <li v-else class="page-item disabled"  @click.prevent>
              <a class="page-link mylink" href="#" tabindex="3">Previous</a>
        </li>

        <li class="page-item" @click.prevent>
              <a class="page-link" href="#">{{pagination.from}} - {{pagination.to}} of {{pagination.total}}</a>
        </li>

       
        <li v-if="pagination.nextPageUrl" @click.prevent="$emit('next');" class="page-item">
            <a class="page-link mylink" href="#" style="background:#00B0E4; color:#fff !important;">Next</a>
        </li>
       
        <li v-else class="page-item disabled" @click.prevent >
              <a class="page-link mylink" href="#" tabindex="3">Next</a>
        </li>
        </ul>
    </nav>


</template>

<script>
    // name: 'pagination';
    export default {
        props: ['pagination']
    };
</script>

<style scoped>
    .mylink{
        width:80px !important;
    }
</style>